import React, { useState } from "react"
import { useForm } from "react-hook-form"

import * as Styles from "./index.module.scss"

import tamkun from "../../../src/images/p-logo--tamkun.svg"

type FormValues = {
  name: string
  company: string
  email: string
  message: string
}

const dictionary = {
  jp: {
    contact: "",
    contactText: "お話してみませんか？",
    contactText2: "韓国語・英語・日本語、お好きな言語でどうぞ！",
    contactAnnotation: "*のついた項目は入力必須です。",
  },
  ko: {
    contact: "문의사항",
    contactText: "우리 한 번 이야기해볼까요？",
    contactText2: "한국어·영어·일본어, 원하시는 언어로 괜찮아요!",
    contactAnnotation: "* 가 붙어있는 항목은 필수입니다.",
  },
}

export const KoreaJpForm: React.VFC<{ language: "jp" | "ko" }> = ({
  language,
}) => {
  const { register, handleSubmit, errors } = useForm<FormValues>({
    mode: "onChange",
  })

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSubmittedError, setIsSubmittedError] = useState(false)

  const onSubmit = handleSubmit(async data => {
    try {
      const formData = new FormData()
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value)
      })

      const response = await fetch(
        "https://kantam.form.newt.so/v1/MgHWP78CD",

        {
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
          },
        }
      )

      if (response.ok) {
        setIsSubmitted(true) // 送信成功時に状態を更新
        setIsSubmittedError(false) // エラー状態をリセット
      } else {
        // 送信が失敗したらエラーメッセージを表示
        throw new Error("Form submission failed")
      }
    } catch (error) {
      setIsSubmittedError(true) // 送信失敗時に状態を更新
      setIsSubmitted(false) // 送信成功状態をリセット
    }
  })

  //Form内の言語選択で選択された言語を管理、language ("ko" or "jp" を初期状態に設定)
  const [selectedLanguage, setSelectedLanguage] = useState<"ko" | "en" | "jp">(
    language
  )

  type LanguageLabels = {
    [key: string]: {
      language: string
      korean: string
      english: string
      japanese: string
      name: string
      company: string
      email: string
      message: string
      required: string
      privacyPolicyPrefix: string
      privacyPolicy: string
      privacyPolicySuffix: string
      submit: string
      submitComplete: string
      submitError: string
    }
  }

  //各ラベルの言語を定義
  const languageLabels: LanguageLabels = {
    ko: {
      language: "언어",
      korean: "한국어",
      english: "영어",
      japanese: "일본어",
      name: "성함",
      company: "귀사명",
      email: "이메일 주소",
      message: "문의사항 내용",
      required: "문의사항 내용",
      privacyPolicyPrefix: "당사에서 개인 정보를 처리하는 방법에 대해, ",
      privacyPolicy: "개인정보 보호정책",
      privacyPolicySuffix: "에 동의했습니다",
      submit: "전송",
      submitComplete: "문의주셔서 감사합니다",
      submitError: "전송에 실패했습니다. 아래 이메일 주소로 문의하십시오.",
    },
    en: {
      language: "Language",
      korean: "Korean",
      english: "English",
      japanese: "Japanese",
      name: "Name",
      company: "Company",
      email: "Email",
      message: "Message",
      required: "Required",
      privacyPolicyPrefix: "I agree to the ",
      privacyPolicy: "Privacy Policy",
      privacyPolicySuffix: " of TAM Group.",
      submit: "Submit",
      submitComplete: "Submission has been completed",
      submitError:
        "Sending failed. Please contact us at the email address below.",
    },
    jp: {
      language: "言語",
      korean: "韓国語",
      english: "英語",
      japanese: "日本語",
      name: "お名前",
      company: "貴社名",
      email: "メールアドレス",
      message: "お問い合わせ内容",
      required: "必須項目です",
      privacyPolicyPrefix: "当社における個人情報の取り扱いについて、",
      privacyPolicy: "プライバシーポリシー",
      privacyPolicySuffix: "に同意しました。",
      submit: "送信する",
      submitComplete: "送信が完了しました",
      submitError:
        "送信に失敗しました。下記のメールアドレスにお問い合わせください。",
    },
  }

  const handleChangeLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    if (value === "en" || value === "ko" || value === "jp") {
      setSelectedLanguage(value) 
    } else {
      // 不正な値が渡された場合のエラーハンドリング
      console.error("Invalid language selected")
    }
  } 
  //プライバシーポリシーの同意チェックを管理
  const [isAgreed, setIsAgreed] = useState(false)

  return (
    <section
      className={`${Styles.koreaContact} ${Styles.koreaJpContact}`}
      id="koreaContact"
    >
      <div className={Styles.koreaContactInner}>
        <div className={Styles.koreaContactHeadingWrapper}>
          <h2 className={Styles.koreaContactHeading}>
            <span className={Styles.ko}>{dictionary[language].contact}</span>
            <span className={Styles.en}>Contact</span>
          </h2>
          <p className={Styles.koreaContactText}>
            {dictionary[language].contactText}
            <br />
            {dictionary[language].contactText2}
          </p>
          <p className={Styles.koreaContactAnnotation}>
            {dictionary[language].contactAnnotation}
          </p>
        </div>
        <div className={Styles.koreaContactFormWrapper}>
          {!isSubmitted && !isSubmittedError && (
            <form className={Styles.koreaContactForm} onSubmit={onSubmit}>
              <div className={Styles.koreaContactItem}>
                <label className={Styles.koreaContactLabel}>
                  {languageLabels[selectedLanguage].language}*
                </label>
                <div className={Styles.koreaContactLanguageInputContainer}>
                  {language === "jp" ? (
                    <>
                      <label className={Styles.koreaContactLanguageLabel}>
                        <input
                          className={Styles.koreaContactLanguageInput}
                          type="radio"
                          name="language"
                          value="jp"
                          checked={selectedLanguage === "jp"}
                          onChange={handleChangeLanguage}
                        />
                        <span className={Styles.radioMark}></span>
                        <span>{languageLabels[selectedLanguage].japanese}</span>
                      </label>
                      <label className={Styles.koreaContactLanguageLabel}>
                        <input
                          type="radio"
                          name="language"
                          value="ko"
                          checked={selectedLanguage === "ko"}
                          onChange={handleChangeLanguage}
                        />
                        <span className={Styles.radioMark}></span>
                        <span>{languageLabels[selectedLanguage].korean}</span>
                      </label>
                    </>
                  ) : (
                    <>
                      <label className={Styles.koreaContactLanguageLabel}>
                        <input
                          type="radio"
                          name="language"
                          value="ko"
                          checked={selectedLanguage === "ko"}
                          onChange={handleChangeLanguage}
                        />
                        <span className={Styles.radioMark}></span>
                        <span>{languageLabels[selectedLanguage].korean}</span>
                      </label>
                      <label className={Styles.koreaContactLanguageLabel}>
                        <input
                          className={Styles.koreaContactLanguageInput}
                          type="radio"
                          name="language"
                          value="jp"
                          checked={selectedLanguage === "jp"}
                          onChange={handleChangeLanguage}
                        />
                        <span className={Styles.radioMark}></span>
                        <span>{languageLabels[selectedLanguage].japanese}</span>
                      </label>
                    </>
                  )}
                  <label className={Styles.koreaContactLanguageLabel}>
                    <input
                      className={Styles.koreaContactLanguageInput}
                      type="radio"
                      name="language"
                      value="en"
                      checked={selectedLanguage === "en"}
                      onChange={handleChangeLanguage}
                    />
                    <span className={Styles.radioMark}></span>
                    <span>{languageLabels[selectedLanguage].english}</span>
                  </label>
                </div>
              </div>
              <div className={Styles.koreaContactItemContainer}>
                <div className={Styles.koreaContactItem}>
                  <label className={Styles.koreaContactLabel} htmlFor="name">
                    {languageLabels[selectedLanguage].name}*
                    {errors.name && (
                      <span
                        className={Styles.errorMessage}
                        id="error-name-required"
                        aria-live="assertive"
                      >
                        {errors.name.message}
                      </span>
                    )}
                  </label>
                  <input
                    className={`${Styles.inputText} ${Styles.inputReset}`}
                    id="name"
                    name="name"
                    type="text"
                    ref={register({
                      required: languageLabels[selectedLanguage].required,
                    })}
                    aria-describedby="error-name-required"
                  />
                </div>
                <div className={Styles.koreaContactItem}>
                  <label className={Styles.koreaContactLabel} htmlFor="company">
                    {languageLabels[selectedLanguage].company}
                  </label>
                  <input
                    className={`${Styles.inputText} ${Styles.inputReset}`}
                    id="company"
                    name="company"
                    ref={register}
                    type="text"
                  />
                </div>
                <div className={Styles.koreaContactItem}>
                  <label className={Styles.koreaContactLabel} htmlFor="email">
                    {languageLabels[selectedLanguage].email}*
                    {errors.email && (
                      <span
                        className={Styles.errorMessage}
                        id="error-name-required"
                        aria-live="assertive"
                      >
                        {errors.email.message}
                      </span>
                    )}
                  </label>
                  <input
                    className={`${Styles.inputText} ${Styles.inputReset}`}
                    id="email"
                    name="email"
                    ref={register({
                      required: languageLabels[selectedLanguage].required,
                    })}
                    type="email"
                    aria-describedby="error-email-required"
                  />
                </div>
                <div className={Styles.koreaContactItem}>
                  <label className={Styles.koreaContactLabel} htmlFor="message">
                    {languageLabels[selectedLanguage].message}*
                    {errors.message && (
                      <span
                        className={Styles.errorMessage}
                        id="error-name-required"
                        aria-live="assertive"
                      >
                        {errors.message.message}
                      </span>
                    )}
                  </label>
                  <textarea
                    className={`${Styles.inputTextArea} ${Styles.inputReset}`}
                    id="message"
                    name="message"
                    ref={register({
                      required: languageLabels[selectedLanguage].required,
                    })}
                    aria-describedby="error-message-required"
                  ></textarea>
                </div>
              </div>
              <div className={Styles.koreaContactPrivacyPolicy}>
                <input
                  type="checkbox"
                  id="privacyPolicy"
                  aria-describedby="privacyPolicyDesc"
                  checked={isAgreed}
                  onChange={e => setIsAgreed(e.target.checked)}
                  aria-label="Agree to the Privacy Policy"
                />
                <label
                  className={Styles.koreaContactPrivacyPolicyLabel}
                  htmlFor="privacyPolicy"
                />
                <p
                  id="privacyPolicyDesc"
                  className={Styles.koreaContactPrivacyPolicyText}
                >
                  {languageLabels[selectedLanguage].privacyPolicyPrefix}
                  <span>
                    <a
                      href="https://www.tam-tam.co.jp/privacy/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {languageLabels[selectedLanguage].privacyPolicy}
                    </a>
                  </span>
                  {languageLabels[selectedLanguage].privacyPolicySuffix}
                </p>
              </div>
              <div className={Styles.koreaContactSubmitButtonWrapper}>
                <button
                  className={Styles.koreaContactSubmitButton}
                  type="submit"
                  disabled={!isAgreed}
                >
                  {languageLabels[selectedLanguage].submit}
                </button>
              </div>
            </form>
          )}
          {isSubmitted && (
            <div className={Styles.koreaContactSubmittedMessageWrapper}>
              <p className={Styles.koreaContactSubmittedMessage}>
                {languageLabels[selectedLanguage].submitComplete}！
              </p>
              <img
                src={tamkun}
                alt="TAM-kun illust"
                width="200"
                height=""
                decoding="async"
                loading="lazy"
              />
            </div>
          )}
          {isSubmittedError && (
            <div className={Styles.koreaContactSubmittedErrorWrapper}>
              <p className={Styles.koreaContactSubmittedError}>
                {languageLabels[selectedLanguage].submitError}
                <br />
                <a href="m&#97;i&#108;t&#111;:t&#97;m_&#107;&#111;&#114;&#101;ai&#110;f&#111;@&#116;&#97;&#109;-t&#97;&#109;&#46;&#99;o.j&#112;">
                  t&#97;m_&#107;&#111;&#114;&#101;ai&#110;f&#111;@&#116;&#97;&#109;-t&#97;&#109;&#46;&#99;o.j&#112;
                </a>
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
